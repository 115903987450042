import Head from "next/head";
import * as React from "react";
import Nav from "@/components/Nav";
import Footer from "@/components/Footer";
import PageError from "@/components/PageError";

const Custom404 = () => {
	return (
		<>
			<Head>
				<title>Page Not Found | Savely</title>
			</Head>
			<div className="h-100 d-flex flex-column">
				<Nav theme="dark" />
				<PageError title="Oops, we couldn't find that." />
				<Footer />
			</div>
		</>
	);
};

export default Custom404;
